<template>
  <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
    <div class="ant-pro-account-avatar">
      <span class="ant-pro-account-nickname">{{ nickname }}</span>
      <!-- <a-avatar size="small" src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png" class="antd-pro-global-header-index-avatar" /> -->
      <!-- <span>{{ currentUser.name }}</span> -->
      <div></div>
    </div>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
        <!--        <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          {{ $t('menu.account.center') }}
        </a-menu-item>
        <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
          <a-icon type="setting" />
          {{ $t('menu.account.settings') }}
        </a-menu-item> -->
        <!-- <a-menu-divider v-if="menu" /> -->
        <a-menu-item key="logout" @click="deleteVisible = true">
          <!-- <a-icon type="logout" /> -->
          <a-modal
            :visible="deleteVisible"
            :centered="true"
            :closable="false"
            :width="444"
          >
            <template #title>
              <div class="ant-row-flex-space-between ant-row-flex">
                <div style="height: 30px; line-height: 30px; display: flex">
                  <my-icon
                    type="icon-icon_feedback_info"
                    style="color: #ffad0d; font-size: 24px; margin-top: 3px"
                  ></my-icon>
                  <div
                    style="font-size: 16px; color: #252929; margin-left: 12px"
                  >
                    提示
                  </div>
                </div>
                <div style="height: 30px; line-height: 30px">
                  <my-icon
                    @click="deleteVisible = false"
                    type="icon-icon_close"
                    style="color: #545757; font-size: 24px; margin-top: 3px"
                  ></my-icon>
                </div>
              </div>
            </template>

            <div
              style="
                margin-top: 8px;
                margin-left: 36px;
                width: 312px;
                padding-bottom: 24px;
              "
            >
              您确定要注销吗？
            </div>

            <template #footer>
              <a-button
                style="
                  background-color: #f7f7f7;
                  border-radius: 4px;
                  width: 52px;
                  height: 30px;
                  line-height: 30px;
                  color: #545757;
                  font-size: 14px;
                  text-align: center;
                  font-weight: 500;
                "
                @click="deleteVisible = false"
              >
                取消
              </a-button>
              <a-button
                style="
                  background-color: #ffad0d;
                  border-radius: 4px;
                  width: 52px;
                  height: 30px;
                  line-height: 30px;
                  color: #ffffff;
                  font-size: 14px;
                  text-align: center;
                  font-weight: 500;
                  margin-left: 24px;
                "
                @click="bindRoleState"
              >
                确定
              </a-button>
            </template>
          </a-modal>
          {{ $t("menu.account.logout") }}
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script>
import { Modal } from "ant-design-vue";
import storage from "store";

export default {
  name: "AvatarDropdown",
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      deleteVisible: false,
      nickname: "",
    };
  },
  mounted() {
    this.nickname = storage.get("nickname");
    console.log(222, this.nickname);
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: "/account/center" });
    },
    handleToSettings() {
      this.$router.push({ path: "/account/settings" });
    },
    bindRoleState() {
      this.$store.dispatch("Logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    handleLogout(e) {
      Modal.confirm({
        title: this.$t("layouts.usermenu.dialog.title"),
        content: this.$t("layouts.usermenu.dialog.content"),
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch("Logout").then(() => {
            this.$router.push({ name: "login" });
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    // margin-right: 40px;
  }
  :deep(.ant-dropdown-menu-item) {
    width: 142px;
  }
}
.ant-pro-global-header-index-right {
  // margin-right: 40px;
}
.ant-pro-account-avatar {
  width: 142px;
  padding: 0 12px 0 16px;
}
.ant-pro-account-nickname {
  position: relative;
  top: -23px;
}
</style>
