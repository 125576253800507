import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: process.env.NODE_ENV ==='production'?process.env.VUE_APP_API_BASE_URL:
  //   'http://120.79.179.187:8009',
  //   // 'http://120.79.179.187:8080/api',
  //   // 'http://wcg.ponylearn.com/api',
  timeout: 60000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 20003) {
      notification.error({
        message: '提示',
        description: "当前权限已被限制,暂时无法查看该页面,请刷新页面"
      })
    }
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  if (config.method === 'post') {
    let data = config.data

    config.data = {
      platform: 1,
      ...data
    }
  } else if (config.method === 'get') {
    config.params = {
      platform: 1,
      ...config.params
    }
  }

  // config.headers["Origin"] = "http://opdev.ponylearn.com:81/"
  // config.headers["apikey"] = "xjfoperation_1"
  const token = storage.get(ACCESS_TOKEN)
  const bind_role_id = storage.get("bind_role_id")
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    // config.headers[ACCESS_TOKEN] = token

    config.headers["apikey"] = "xjfoperation_" + bind_role_id
    config.headers["authorization"] = 'Bearer ' + token
    // config.headers["authorization"] = 'Bearer ' + "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTUwMjUzMjEsImlhdCI6MTY5NDQyMDUyMSwidXNlciI6IjE1MzYxMzQxNTM1In0.YsJI3QbIPO7MconN-Xw9x4mFsAFxqEYkgd0KrmWdh2M"
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
